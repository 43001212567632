<template>
  <div id="app">
    <!-- Navigation Bar -->
    <NavbarComponent />

    <div class="columns mt-2">
      <aside class="menu column is-2 mx-2">
        <p class="menu-label">General</p>
        <ul class="menu-list">
          <li><a>Dashboard</a></li>
          <li><a>Customers</a></li>
        </ul>
        <p class="menu-label">Administration</p>
        <ul class="menu-list">
          <li><a>Team Settings</a></li>
          <li>
            <a class="is-active">Manage Your Team</a>
            <ul>
              <li><a>Members</a></li>
              <li><a>Plugins</a></li>
              <li><a>Add a member</a></li>
            </ul>
          </li>
          <li><a>Invitations</a></li>
          <li><a>Cloud Storage Environment Settings</a></li>
          <li><a>Authentication</a></li>
        </ul>
        <p class="menu-label">Transactions</p>
        <ul class="menu-list">
          <li><a>Payments</a></li>
          <li><a>Transfers</a></li>
          <li><a>Balance</a></li>
        </ul>
      </aside>
      <!-- Main Content -->
      <div class="container column">
        <h1 class="title">Soal Deret</h1>
        <div class="block">
          <h2 class="py-2"><strong>Soal ke 1 dari 10</strong></h2>
          <h2 class="subtitle">{{ question }}</h2>
        </div>
        <div class="columns">
          <div class="column is-half">
            <div class="field" v-for="(option, index) in options" :key="index">
              <label :class="{ 'radio-selected': selectedOption === index }" class="b-radio radio is-medium">
                <input type="radio" alt="soalhub" name="soalhub_answer" :value="index" v-model="selectedOption"
                  @change="checkAnswer(index)">
                <span class="check"></span>
                <span class="control-label">{{ option }}</span>
              </label>
            </div>
          </div>
        </div>
      </div>
      <div class="column card is-2">
        <div class="card-content">
          <div class="content">
            Lorem ipsum leo risus, porta ac consectetur ac, vestibulum at eros. Donec
            id elit non mi porta gravida at eget metus. Cum sociis natoque penatibus
            et magnis dis parturient montes, nascetur ridiculus mus. Cras mattis
            consectetur purus sit amet fermentum.
          </div>
        </div>
      </div>
      <!--  -->
    </div>



  </div>
</template>

<script>
import 'bulma/css/bulma.min.css';
import 'bulma-radio/css/main.min.css';
import NavbarComponent from './components/NavbarComponent.vue';

export default {
  name: 'App',
  components: {
    NavbarComponent
  },
  data() {
    return {
      question: '44  49  55 | 60  65  71  77  82 |  88  93  99',
      options: ['A. 60', 'B. 65', 'C. 71', 'D. 77', 'E. 82'],
      selectedOption: null
    };
  },
  methods: {
    checkAnswer(index) {
      // Logic to check the selected answer
      console.log("Selected answer:", this.options[index]);
    }
  },
  created() {
    document.title = 'Belajar tinggal belajar di SoalHub - Lulus tes BUMN, CPNS, dan TNI 100%';
  }
}
</script>

<style>
/* You can add custom styles here */
</style>
